import {
    Button,
    Card,
    Carousel,
    CarouselItem,
    Cascader,
    Col,
    Collapse,
    CollapseItem,
    DatePicker,
    Drawer,
    Empty,
    Form,
    FormItem,
    Input,
    InputNumber,
    Link,
    Loading,
    Message,
    MessageBox,
    Option,
    Popover,
    Radio,
    RadioGroup,
    Row,
    Select,
    Table,
    TableColumn,
    TabPane,
    Tabs,
    Timeline,
    TimelineItem,
    Tooltip,
    infiniteScroll
} from 'element-ui'
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'
import Vue from 'vue'
import App from './App.vue'
import './css/common.scss'
import Image from './lib/image'
import './lib/image/image.css'
import Upload from './lib/upload'
import './lib/upload/upload.css'
import router from './router'
Vue.use(Upload)
Vue.use(Image)
Vue.use(Input)
Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Col)
Vue.use(Tooltip)
Vue.use(Link)
Vue.use(DatePicker)
Vue.use(Option)
Vue.use(Row)
Vue.use(Select)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(InputNumber)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Card)
Vue.use(Drawer)
Vue.use(Popover)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Cascader)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Timeline)
Vue.use(TimelineItem)
Vue.use(Empty)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(infiniteScroll)


Vue.component(CollapseTransition.name, CollapseTransition)

import DragItDude from 'vue-drag-it-dude'
Vue.component(DragItDude.name, DragItDude)

const _Loading = Loading
const { directive: loadingDirective, service: loadingService } = _Loading
Vue.use(loadingDirective)
Vue.prototype.$loading = loadingService
Vue.prototype.$confirm = MessageBox.confirm

import ELOverlayAutoClose from '@/components/el-overlay-auto-close'
Vue.use(ELOverlayAutoClose)

import { parseTime } from './utils/index'
import './utils/request'
import requestApi from './utils/requestApi'

Vue.config.productionTip = false
// markdown
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
Vue.use(mavonEditor)

import Print from 'vue-print-nb'
Vue.use(Print)

import htmlToPdf from '@/utils/htmlToPdf'
Vue.use(htmlToPdf)

/*
 * @type : success || cancel
 */
const uni = window.uni
Vue.prototype.backUniApp = type => uni.postMessage({ data: { action: type } })


Vue.prototype.baseUrl = process.env.VUE_APP_BASE_API

Vue.prototype.parseTime = parseTime
Vue.prototype.$message = Message
// Vue.prototype.isMobile = ()=> window.screen.width <= 540

Vue.prototype.isVideo = url => {
    if (!url) return ''
    return ['mp4', 'MP4'].includes(url.substring(url.lastIndexOf('.') + 1))
}
Vue.prototype.isPdf = url => {
    if (!url) return ''
    return ['pdf'].includes(url.substring(url.lastIndexOf('.') + 1))
}
// Vue.prototype.$axios = window.axios

// import Avue from '@smallwei/avue'
import Avue from './lib/avue.min.js'
import '@smallwei/avue/lib/index.css'
Vue.use(Avue, { axios: requestApi });

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
