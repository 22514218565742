import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'add',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Home.vue')
  },
  {
    path: '/upload',
    name: 'UploadFile',
    component: () => import('../views/uploadFile.vue')
  },
  {
    path: '/add',
    name: 'Add',
    component: () => import('../views/add.vue')
  },
  {
    path: '/penrecord',
    name: 'Penrecord',
    component: () => import('../views/penrecord.vue')
  },
  {
    path: '/inquest',
    name: 'Inquest',
    component: () => import('../views/inquest/index')
  },
  {
    path: '/inventory',
    name: 'Inventory',
    component: () => import('../views/inventory/index')
  },
  {
    path: '/loss',
    name: 'Loss',
    component: () => import('../views/loss/index')
  },
  {
    path: '/articles',
    name: 'Articles',
    component: () => import('../views/articles/index.vue')
  },
  {
    path: '/videolist',
    name: 'Videolist',
    component: () => import('../views/videolist/index.vue')
  },
  {
    path: '/share/:hashcode',
    name: 'Share',
    meta: {
      title: '视频预览'
    },
    component: () => import('../views/share/index.vue')
  }, {
    path: '/imageAssociation',
    name: 'ImageAssociation',
    meta: {
      title: '火灾影像关联'
    },
    component: () => import('../views/imageAssociation/index.vue')
  }, {
    path: '/minorFire',
    name: 'MinorFire',
    meta: {
      title: '轻微火灾登记'
    },
    component: () => import('../views/minorFire/index.vue')
  }, {
    path: '/test',
    name: 'test',
    meta: {
      title: '视频上传'
    },
    component: () => import('../views/test.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => { // beforeEach是router的钩子函数，在进入路由前执行
  if (to.meta.title) { // 判断是否有标题
    document.title = to.meta.title
  }
  next() // 执行进入路由，如果不写就不会进入目标页
})

export default router
