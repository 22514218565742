import request from '@/utils/requestApi'

export function uploadFile(params) {
    return request({
        url: '/common/upload',
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data;'
        },
        data: params
    })
}
