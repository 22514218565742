// htmlToPdf.js
// 导出页面为PDF格式
import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'
import { Loading } from 'element-ui';
import { dataURLtoFile } from './index'
import { uploadFile } from '@/api/common'
export default {
  install(Vue, options) {
    Vue.prototype.getPdf = function (targetDom, reportName, isDownload = true) {
      let loadingInstance = Loading.service({ fullscreen: true });
      return new Promise((resolve, reject) => {
        var title = reportName  //DPF标题
        var copyDom = targetDom.cloneNode(true);
        copyDom.style.width = targetDom.scrollWidth + "px";
        copyDom.style.height = targetDom.scrollHeight + 100 + "px";
        document.querySelector("body").appendChild(copyDom);
        let that = this
        window.scrollTo(0, 0);  // 滚动到页面最上方
        html2Canvas(copyDom, {
          allowTaint: true,  //开启跨域
          useCORS: true,
          scrollX: 0,
          scale: 4
        }).then(function (canvas) {
          copyDom.remove();
          // that.pdfShow =false
          let contentWidth = canvas.width
          let contentHeight = canvas.height
          // 一页pdf显示html页面生成的canvas高度;
          let pageHeight = contentWidth / 592.28 * 841.89
          // 未生成pdf的html页面高度
          let leftHeight = contentHeight
          // 页面偏移
          let position = 0
          // a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
          let imgWidth = 595.28
          let imgHeight = 592.28 / contentWidth * contentHeight
          let pageData = canvas.toDataURL('image/jpeg', 1.0)

          let PDF = new JsPDF('', 'pt', 'a4')
          // 有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
          // 当内容未超过pdf一页显示的范围，无需分页
          if (leftHeight < pageHeight) {
            PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
          } else {
            while (leftHeight > 0) {
              PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
              leftHeight -= pageHeight
              position -= 841.89
              if (leftHeight > 0) {
                PDF.addPage()
              }
            }
          }
          if (isDownload) {
            PDF.save(title + '.pdf')
            loadingInstance.close();
          } else {
            var pdfData = PDF.output('datauristring')//获取base64Pdf
            loadingInstance.close();
            resolve(pdfData)
          }
        }).catch(() => {
          reject(false);
          loadingInstance.close();

        });

      })

    }
    Vue.prototype.uploadPdf = async function (title, base64) {
      /**
     * val 决定走不走上传，默认为不上传
     * download 默认是下载
     **/
      let myfile = dataURLtoFile(base64, title)
      var formdata = new FormData();
      formdata.append("file", myfile); // 文件对象
      let uploadRes = await uploadFile(formdata)
      console.log(uploadRes);

      // fetch(`${this.baseUrl}/archives/notice`, {
      //   method: "PUT",
      //   headers: { ...this.headers, "Content-Type": "application/json;charset=UTF-8" },
      //   mode: "cors",
      //   body: JSON.stringify(data)
      // }).then((response) => response.json()).then(() => {
      //   this.$message.success('保存成功')
      // loadingInstance.close();

    }
  }
}
