window.axios.interceptors.request.use(config => {
  // 是否需要设置 token
  // const _token = (config.headers || {})._token
  // const isToken = (config.headers || {}).isToken === false
  // if ((getToken() || _token) && !isToken) {
  //   config.headers['Authorization'] = 'Bearer ' + (getToken() || _token) // 让每个请求携带自定义token 请根据实际情况自行修改
  // }
  // // get请求映射params参数
  if (config.method === 'get' && config.params && config.params.Authorization) {
    config.headers.Authorization = config.params.Authorization
  }
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
})

